
export const getLabelConfigs = (isEquity) => {
  return {
    placeOrderLabelConfigs: {
      mis: 'INTRADAY',
      cnc: 'DELIVERY',
      nrml: 'DELIVERY',
    },
  };
};

export const getDefaultConfigs = (isEquity, isFuture) => {
  return {
    productCode: (isEquity) ? 'CNC' : 'NRML',
  };
};
import { getDynamicAppConfigs, initDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import Instrument from 'habitual-analytics/models/instrument/index';
import _ from 'lodash';
import {
  getInstrumentDetailsByExchangeTokens
} from 'habitual-analytics/api/services/getInstrumentDetailsByExchangeTokens';


export const parseOrderDetailToGetTradingSymbolObj = (orderDetail) => {
  const instrumentDetailsByExchangeTokens = _.get(
    getDynamicAppConfigs(),
    'instrumentDetailsByExchangeTokens',
    {}
  );
  const instrumentDetail = _.find(
    instrumentDetailsByExchangeTokens,
    (value, key) => key == orderDetail?.token || key == _.get(orderDetail, 'exch_tsym[0].token', '')
  );

  if (_.isEmpty(instrumentDetail)) return null;

  if (instrumentDetail?.type == 'EQ') {
    return new Instrument({
      symbol: instrumentDetail.tradingsymbol,
    });
  } else {
    return new Instrument({
      symbol: instrumentDetail.symbol,
      expiryDate: instrumentDetail.expiryDate,
      strikePrice: instrumentDetail.strikePrice,
      optionType: instrumentDetail.type,
    });
  }
};


export const parseTradingSymbolByExchangeToken = async (orderDetails) => {
  const exchangeTokens = _.map(orderDetails, (arr) =>
    _.get(arr, 'token', '') || _.get(arr, 'exch_tsym[0].token', '')
  );
  const instrumentDetailsByExchangeTokens = _.get(
    getDynamicAppConfigs(),
    'instrumentDetailsByExchangeTokens',
    {}
  );
  const missingTokens = _.filter(exchangeTokens, (token) =>
    _.isEmpty(instrumentDetailsByExchangeTokens[token])
  );

  if (missingTokens.length > 0) {
    const fetchedDetails = await getInstrumentDetailsByExchangeTokens(
      missingTokens
    );
    const formattedFetchDetails = _.keyBy(fetchedDetails, 'exchange_token');
    const mergedDetails = _.merge({}, instrumentDetailsByExchangeTokens, formattedFetchDetails);
    initDynamicAppConfigs({
      instrumentDetailsByExchangeTokens: mergedDetails,
    });
  }
};
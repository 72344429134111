import React, { lazy, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { reactToastify, TOASTIFY_TYPES } from 'globals/utils/reactToastify';
import SingletonLiveTickerWrapper from 'components/SingletonLiveTickerWrapper/index';
import { useDispatch, useSelector } from 'react-redux';
import AutoRefreshModal from 'components/AutoRefreshModal/index';
import RiskDisclosureModal from 'components/RiskDisclourseModal/index';
import { updateCommonConfigs } from 'redux_store/action/index';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import { useNavigateSearch } from 'globals/hooks/navigateWithQueryParams';
import { getVerificationDetails } from './verifier';
import TelegramPopUp from '../TelegramPopUp/index';
import MobilePopup from '../MobilePopup/index';
import { OriginUtility } from '../../utils';
import { InstaConstants } from 'habitual-analytics/constants/instaConstants';
import withSuspense from '../withSuspense';
import { retryLazyLoad } from 'globals/utils/lazyLoad';

const AppLayout = withSuspense(lazy(
  () => retryLazyLoad(() => import('pages/AppLayout')))
);

const propTypes = {};

const defaultProps = {};

const Authenticator = () => {
  const { shouldShowPlayStorePopUp,
    shouldShowTelegramPopUp,
    shouldEnableDisclosureModal } = getDynamicAppConfigs()?.domainConfigs;

  const dispatch = useDispatch();
  const { currentBrokerName,
    userRunType, historicalDateAndTime } = useSelector((state) => state.commonConfigs);
  const navigate = useNavigateSearch();

  const isUserSignedIn = InstaConstants.get('isUserSignedIn');
  const [isUserLoggedIn, setUserLoggedIn] = useState(isUserSignedIn);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const verificationDetails = await getVerificationDetails(userRunType, historicalDateAndTime);
      setUserLoggedIn(verificationDetails?.isUserLoggedIn);
      dispatch(updateCommonConfigs({ userRunType, historicalDateAndTime }));
      setIsLoading(false);
      document.body.classList.add(currentBrokerName || 'guest');
      if (verificationDetails?.message) {
        reactToastify(TOASTIFY_TYPES.ERROR, verificationDetails?.message);
      }
    })();
  }, []);

  const navigateToLogin = () => {
    OriginUtility.setOrigin();
    navigate('/login');
    // TODO is below reload necassary??
    if (!isUserSignedIn) window.location.reload();
  };

  const renderContent = (
    <>
      <Outlet />
      {shouldEnableDisclosureModal && <RiskDisclosureModal />}
      <div className="popup-fixed">
        {shouldShowPlayStorePopUp && <MobilePopup />}
        {shouldShowTelegramPopUp && <TelegramPopUp />}
      </div>
      <AutoRefreshModal />
    </>
  );

  const renderAppLayout = () => {
    return !isLoading && (
      <SingletonLiveTickerWrapper>
        <AppLayout>
          {renderContent}
        </AppLayout>
      </SingletonLiveTickerWrapper>
    );
  };

  return isUserLoggedIn ? (
    renderAppLayout()
  ) : (
    navigateToLogin()
  );
};

Authenticator.propTypes = propTypes;
Authenticator.defaultProps = defaultProps;

export default Authenticator;

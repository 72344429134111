import _ from 'lodash';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import {
  orderTableHeaders,
  tradeTableHeaders,
  postitionTableHeaders,
  holdingTableHeaders
} from './tableHeaders';
import {
  parseCancelOrder,
  parseMarginCalculator,
  parseModifyOrder,
  parseOrderDetails,
  parsePlaceOrder,
} from './parser';
import { wrappedFetch } from 'habitual-analytics/api/services/base';
import { REST_URLS } from 'habitual-analytics/api/resturls/index';

const getHeaders = (shouldAttachCookie = true) => {
  const { brokerExtras } = getDynamicAppConfigs().brokerConfigs;
  const customCookie = `IIFLMarcookie=${brokerExtras['IIFLMarCookie']}`;

  const { envs } = getDynamicAppConfigs();
  const { IIFL_OCP_APIM_SUBSCRIPTION_KEY } = envs;
  return {
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': IIFL_OCP_APIM_SUBSCRIPTION_KEY,
    ...(shouldAttachCookie && { 'custom-cookie': customCookie })
  };
};

const getRequestUri = (method) => {
  const baseUrl = REST_URLS().BROKER_PROXY;
  return `${baseUrl}?broker=iifl_nova&method=${method}`;
};

const isValidAccessToken = async () => {
  const { brokerClientId, brokerExtras } = getDynamicAppConfigs().brokerConfigs;
  const { userKey, userId, appName, password } = brokerExtras;

  const body = {
    head: {
      appName: appName,
      appVer: '1.0',
      key: userKey,
      osName: 'WEB',
      userId: userId,
      password: password,
      requestCode: 'IIFLMarRQBackoffClientProfile'
    },
    body: {
      ClientCode: brokerClientId,
    },
  };

  const requestHeaders = getHeaders();

  const parameters = {
    method: 'POST',
    headers: requestHeaders,
    body: JSON.stringify(body),
  };

  return await wrappedFetch(getRequestUri('authenticateUser'), parameters)
    .then((response) => response.json())
    .then((data) => {
      return data['body']['Status'] === 0 ? true : false;
    })
    .catch(() => false);
};

const fetchOrderBook = () => {
  const { brokerClientId, brokerExtras } = getDynamicAppConfigs().brokerConfigs;
  const { userKey, userId, appName, password } = brokerExtras;
  const requestHeaders = getHeaders();

  const body = {
    head: {
      requestCode: 'IIFLMarRQOrdBkV3',
      key: userKey,
      appVer: '1.0',
      appName: appName,
      osName: 'Android',
      userId: userId,
      password: password,
    },
    body: {
      ClientCode: brokerClientId,
    },
  };

  const parameters = {
    method: 'POST',
    headers: requestHeaders,
    body: JSON.stringify(body),
  };

  return wrappedFetch(getRequestUri('orderBook'), parameters)
    .then((response) => response.json())
    .then(async (result) => ({
      orderBookDetails: _.compact(
        _.orderBy(await parseOrderDetails(result['body']['OrderBookDetail'], 'order'), ['time'], ['desc'])
      ),
      headers: orderTableHeaders,
    }));
};

const fetchTradeBook = () => {
  const { brokerClientId, brokerExtras } = getDynamicAppConfigs().brokerConfigs;
  const { userKey, userId, appName, password } = brokerExtras;
  const requestHeaders = getHeaders();

  const reqBodyHeaders = {
    requestCode: 'IIFLMarRQTrdBkV2',
    key: userKey,
    appVer: '1.0',
    appName: appName,
    osName: 'Android',
    userId: userId,
    password: password,
  };

  const body = {
    head: reqBodyHeaders,
    body: {
      ClientCode: brokerClientId,
    },
  };

  const parameters = {
    method: 'POST',
    headers: requestHeaders,
    body: JSON.stringify(body),
  };

  return wrappedFetch(getRequestUri('tradeBook'), parameters)
    .then((response) => response.json())
    .then(async (result) => {
      return {
        tradeBookDetails: _.compact(
          _.orderBy(await parseOrderDetails(result['body']['TradeBookDetail'], 'trade'), ['time'], ['desc'])
        ),
        headers: tradeTableHeaders,
      };
    });
};

const fetchPositionBook = async () => {
  const { brokerClientId, brokerExtras } = getDynamicAppConfigs().brokerConfigs;
  const { userKey, userId, appName, password } = brokerExtras;
  const body = {
    head: {
      requestCode: 'IIFLMarRQNPNWV2',
      key: userKey,
      appVer: '1.0',
      appName: appName,
      osName: 'Android',
      userId: userId,
      password: password,
    },
    body: {
      Clientcode: brokerClientId
    },
  };

  const parameters = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(body),
  };

  return await wrappedFetch(getRequestUri('positionBook'), parameters)
    .then((response) => response.json())
    .then(async (result) => {
      const positionBookDetails = await parseOrderDetails(result?.body?.NetPositionDetail, 'position');
      return {
        positionBookDetails: _.compact(
          _.orderBy(
            positionBookDetails,
            ['extraDetails.isOpenPosition'],
            ['desc']
          )
        ),
        headers: postitionTableHeaders,
      };
    });
};

const fetchHoldingBook = () => {
  const { brokerClientId, brokerExtras } = getDynamicAppConfigs().brokerConfigs;
  const { userKey, userId, appName, password } = brokerExtras;
  const requestHeaders = getHeaders();

  const body = {
    head: {
      requestCode: 'IIFLMarRQHoldingV3',
      key: userKey,
      appVer: '1.0',
      appName: appName,
      osName: 'Android',
      userId: userId,
      password: password,
    },
    body: {
      ClientCode: brokerClientId,
    },
  };

  const parameters = {
    method: 'POST',
    headers: requestHeaders,
    body: JSON.stringify(body),
  };

  return wrappedFetch(getRequestUri('holdings'), parameters)
    .then((response) => response.json())
    .then(async (result) => {
      return {
        holdingBookDetails: _.compact(await parseOrderDetails(_.get(result, 'body.Data', []), 'holdings')),
        headers: holdingTableHeaders,
      };
    });
};

const placeOrder = async (placeOrderConfigs) => {
  const { brokerExtras } = getDynamicAppConfigs().brokerConfigs;
  const formattedConfig = await Promise.all(
    _.map(placeOrderConfigs, async (orderConfig) => {
      const orderDetails = await parsePlaceOrder(orderConfig, brokerExtras);
      const requestBody = JSON.stringify(orderDetails);
      const requestHeaders = getHeaders();

      const parameters = {
        method: 'POST',
        headers: requestHeaders,
        body: requestBody,
      };

      return wrappedFetch(getRequestUri('placeOrder'), parameters)
        .then((response) => response.json())
        .catch((e) => [e]);
    })
  );

  return _.chain(formattedConfig)
    .map(({ body, head }) => (head['statusDescription'] === 'Success' ? body['BrokerOrderID'] : null))
    .value();
};

const cancelPlaceOrder = (orderConfig) => {
  const { brokerExtras, brokerClientId } = getDynamicAppConfigs().brokerConfigs;

  const orderDetail = parseCancelOrder(orderConfig, brokerClientId, brokerExtras);
  const requestBody = JSON.stringify(orderDetail);
  const requestHeaders = getHeaders();

  const parameters = {
    method: 'POST',
    headers: requestHeaders,
    body: requestBody,
  };

  return wrappedFetch(getRequestUri('cancelPlaceOrder'), parameters).then((response) => response.json());
};

const fetchMarginCalculator = async (orderConfigs) => {
  const requestHeaders = getHeaders();

  const formattedResponse = await Promise.all(
    _.map(orderConfigs, async (orderConfig) => {
      const orderDetails = await parseMarginCalculator(orderConfig);
      const requestBody = JSON.stringify(orderDetails);

      const parameters = {
        method: 'POST',
        headers: requestHeaders,
        body: requestBody,
        credentials: 'omit',
      };

      return wrappedFetch(getRequestUri('marginCalculator'), parameters)
        .then((response) => response.json())
        .then((data) => {
          return data;
        })
        .catch((e) => [e.message]);
    })
  );

  const totalMarginRequired =
    _.chain(formattedResponse)
      .map(({ body }) => parseFloat(body['requiredMargin']))
      .sum()
      .value() || 0;

  const totalMarginAvailable = _.parseInt(formattedResponse?.[0]['body']['albMargintoTrade']) || 0;

  return { totalMarginRequired, totalMarginAvailable };
};

const modifyPlaceOrder = async (placeOrderConfigs) => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;

  const orderDetails = await parseModifyOrder(placeOrderConfigs?.[0], brokerClientId);
  const requestbody = JSON.stringify(orderDetails);

  const requestHeaders = getHeaders();

  const parameters = {
    method: 'POST',
    headers: requestHeaders,
    body: requestbody,
  };

  return wrappedFetch(getRequestUri('modifyOrder'), parameters)
    .then((response) => response.json())
    .catch((e) => [e]);
};

export {
  fetchOrderBook,
  fetchPositionBook,
  fetchTradeBook,
  fetchHoldingBook,
  placeOrder,
  isValidAccessToken,
  cancelPlaceOrder,
  fetchMarginCalculator,
  modifyPlaceOrder,
};

import _ from 'lodash';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import { wrappedFetch } from 'habitual-analytics/api/services/base/index';
import {
  parseCancelOrder,
  parseModifyOrder,
  parseOrderDetails,
  parsePlaceOrder,
} from './parser';
import {
  holdingTableHeaders,
  orderTableHeaders,
  postitionTableHeaders,
  tradeTableHeaders,
} from './tableHeaders';
import { REST_URLS } from 'habitual-analytics/api/resturls/index';

const getApplicationHeaders = (reqBody) => {
  const { brokerClientAccessToken, brokerClientId } =
    getDynamicAppConfigs().brokerConfigs;

  const { ALMONDZ_API_KEY } = getDynamicAppConfigs().envs;
  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'authorization': brokerClientAccessToken,
      'api-key': ALMONDZ_API_KEY,
    },
    body: JSON.stringify({
      entity_id: brokerClientId,
      source: 'M',
      data: {
        client_id: brokerClientId,
        ...reqBody && reqBody,
      }
    }),
  };
};

const getRequestUri = (method, qs = '', path = '') => {
  const baseUrl = REST_URLS().BROKER_PROXY;

  const queryString = qs ? `&${qs}` : '';
  const pathString = path ? `path = ${path}` : '';

  return `${baseUrl}?broker=almondz&method=${method}${queryString}&${pathString}`;
};

const isValidAccessToken = async () => {
  const parameters = getApplicationHeaders();

  return await wrappedFetch(getRequestUri('orderBook'), parameters)
    .then((response) => response.json())
    .then((result) => result?.status === 'success')
    .catch(() => false);
};

const placeOrder = async (orderDetails) => {
  const fetchedOrderDetails = await Promise.all(
    _.map(orderDetails, async (orderConfig) => {
      const formattedConfig = await parsePlaceOrder(orderConfig);
      const paramaters = getApplicationHeaders(formattedConfig);

      return wrappedFetch(getRequestUri('placeOrder'), paramaters)
        .then((res) => res.json())
        .then((result) => result)
        .catch((e) => [e]);
    })
  );

  return _.chain(fetchedOrderDetails)
    .map(({ status, data }) => status === 'success' ? data?.[0]?.order_no : { status: 'failed' })
    .filter((value) => value !== null)
    .value();
};

const fetchOrderBook = async () => {
  const paramaters = getApplicationHeaders();

  return await wrappedFetch(getRequestUri('orderBook'), paramaters)
    .then((res) => res.json())
    .then(async (result) => {
      return {
        orderBookDetails: _.compact(
          _.orderBy(
            await parseOrderDetails(result?.data, 'orders'),
            ['time'],
            ['desc']
          )
        ),
        headers: orderTableHeaders,
      };
    });
};

const fetchTradeBook = async () => {
  const parameters = getApplicationHeaders();

  return await wrappedFetch(getRequestUri('orderBook'), parameters)
    .then((response) => response.json())
    .then(async (result) => {
      const parsedData = await parseOrderDetails(result?.data, 'orders');
      return {
        tradeBookDetails: _.chain(parsedData)
          .compact()
          .filter(order => order.status === 'executed')
          .orderBy(['time'], ['desc'])
          .value(),
        headers: tradeTableHeaders,
      };
    });
};

const fetchPositionBook = async () => {
  const parameters = getApplicationHeaders();

  return await wrappedFetch(getRequestUri('positionBook'), parameters)
    .then((response) => response.json())
    .then(async (result) => {
      return {
        positionBookDetails: _.compact(
          _.orderBy(
            await parseOrderDetails(
              result?.data,
              'position'
            ),
            ['extraDetails.isOpenPosition'],
            ['desc']
          )
        ),
        headers: postitionTableHeaders,
      };
    });
};

const fetchHoldingBook = async () => {
  const parameters = getApplicationHeaders();

  return await wrappedFetch(getRequestUri('holdings'), parameters)
    .then((response) => response.json())
    .then(async (result) => {
      return {
        holdingBookDetails: _.compact(
          await parseOrderDetails(result?.data, 'holdings')
        ),
        headers: holdingTableHeaders,
      };
    });
};

const cancelPlaceOrder = (orderDetails) => {
  const formattedConfig = parseCancelOrder(orderDetails, 'cancelOrder');
  const parameters = getApplicationHeaders(formattedConfig);

  return wrappedFetch(getRequestUri('cancelPlaceOrder'), parameters).then(
    (response) => response.json()
  );
};

const modifyPlaceOrder = async (placeOrderConfigs) => {
  const orderConfig = await parseModifyOrder(placeOrderConfigs?.[0]);
  const parameters = getApplicationHeaders(orderConfig);

  return wrappedFetch(getRequestUri('modifyOrder'), parameters)
    .then((response) => response.json())
    .then(({ status, data }) =>
      status === 'success' ? [data[0]?.order_no] : null
    );
};

export {
  isValidAccessToken,
  placeOrder,
  fetchOrderBook,
  fetchTradeBook,
  fetchPositionBook,
  fetchHoldingBook,
  cancelPlaceOrder,
  modifyPlaceOrder
};

import { getDynamicAppConfigs, initDynamicAppConfigs, } from 'habitual-analytics/constants/dynamicAppConfigs';
import {
  getInstrumentDetailsByExchangeTokens
} from 'habitual-analytics/api/services/getInstrumentDetailsByExchangeTokens';
import Instrument from 'habitual-analytics/models/instrument/index';
import _ from 'lodash';

export const parseOrderDetailToGetTradingSymbolObj = (orderDetail) => {
  const instrumentDetailsByExchangeTokens = _.get(getDynamicAppConfigs(),
    'instrumentDetailsByExchangeTokens', {}
  );
  const fetchedDetails = _.find(instrumentDetailsByExchangeTokens,
    (value, key) => key == orderDetail?.security_id || key == orderDetail?.nse_security_id
  );

  if (_.isEmpty(fetchedDetails)) {
    return null;
  }

  if (fetchedDetails?.type === 'EQ') {
    return new Instrument({
      symbol: fetchedDetails?.tradingsymbol,
    });
  } else if (fetchedDetails?.segment === 'NFO-OPT') {
    return new Instrument({
      symbol: fetchedDetails.symbol,
      expiryDate: fetchedDetails.expiryDate,
      strikePrice: fetchedDetails.strikePrice,
      optionType: fetchedDetails.type,
    });
  } else {
    return new Instrument({
      symbol: fetchedDetails.symbol,
      expiryDate: fetchedDetails.expiryDate,
    });
  }
};

export const parseInstrumentDetailsByExchangeToken = async (orderDetails) => {
  const exchangeTokens =
    _.map(orderDetails, (arr) => _.get(arr, 'security_id', '') || _.get(arr, 'nse_security_id', ''));

  const instrumentDetailsByExchangeTokens = _.get(getDynamicAppConfigs(),
    'instrumentDetailsByExchangeTokens', {});

  const missingTokens = _.filter(exchangeTokens, (token) =>
    _.isEmpty(instrumentDetailsByExchangeTokens[token]));

  if (missingTokens.length > 0) {
    const fetchedDetails = await getInstrumentDetailsByExchangeTokens(missingTokens);
    const formattedFetchDetails = _.keyBy(fetchedDetails, 'exchange_token');
    const mergedDetails = _.merge({}, instrumentDetailsByExchangeTokens, formattedFetchDetails);
    initDynamicAppConfigs({
      instrumentDetailsByExchangeTokens: mergedDetails,
    });
  }
};
export const retryLazyLoad = (importFunction, retries = 3, delay = 1000) => {  
  const attempt = (remainingTries) =>
    importFunction().catch((error) => {
      if (remainingTries <= 1) throw error;
      return new Promise((resolve) =>
        setTimeout(() => resolve(attempt(remainingTries - 1)), delay)
      );
    });

  return attempt(retries);
};

// import AuthProvider from 'AuthProvider/index';
import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import Authenticator from 'globals/components/Authenticator/index';
import PrivacyPolicy from 'pages/PrivacyPolicy/index';
import { useSelector } from 'react-redux';
import withSuspense from 'globals/components/withSuspense';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import { retryLazyLoad } from 'globals/utils/lazyLoad';

const Dashboard = withSuspense(lazy(() => retryLazyLoad(() => import('pages/Dashboard/index'))));
const NotFound = withSuspense(lazy(() => retryLazyLoad(() => import('globals/components/NotFound/index'))));
const Basket = withSuspense(lazy(() => retryLazyLoad(() => import('pages/Basket/index'))));
const CombinedPremium = withSuspense(
  lazy(() => retryLazyLoad(() => import('components/CombinedPremium/index')))
);
const Options = withSuspense(lazy(() => retryLazyLoad(() => import('pages/Options/index'))));
const Orders = withSuspense(lazy(() => retryLazyLoad(() => import('pages/Orders/index'))));
const Login = withSuspense(lazy(() => retryLazyLoad(() => import('pages/Login/index'))));
const Holdings = withSuspense(lazy(() => retryLazyLoad(() => import('pages/Holdings/index'))));
const Settings = withSuspense(lazy(() => retryLazyLoad(() => import('pages/Settings/index'))));
const WatchList = withSuspense(lazy(() => retryLazyLoad(() => import('components/WatchList/index'))));
const Plan = withSuspense(lazy(() => retryLazyLoad(() => import('pages/Plan/index'))));
const TermsAndConditions = withSuspense(
  lazy(() => retryLazyLoad(() => import('pages/TermsAndConditions/index')))
);
const PublisherApi = withSuspense(lazy(() => retryLazyLoad(() => import('components/PublisherApi/index'))));
const Partners = withSuspense(lazy(() => retryLazyLoad(() => import('pages/Partners/index'))));


const Routers = () => {
  const { isB2B } = getDynamicAppConfigs()?.domainConfigs;
  const { currentBrokerName } = useSelector((state) => state?.commonConfigs);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Authenticator />}>
        <Route path="/" element={<Dashboard />} />
      </Route>
      <Route path="/instruments" element={<Authenticator />}>
        <Route path="/instruments" element={<Options />} />
      </Route>
      <Route path="/basket" element={<Authenticator />}>
        <Route path="/basket" element={<Basket />} />
      </Route>
      <Route path="/combined_premium" element={<Authenticator />}>
        <Route path="/combined_premium" element={<CombinedPremium />} />
      </Route>
      <Route path="/orders" element={<Authenticator />}>
        <Route path="/orders" element={<Orders />} />
      </Route>
      <Route path="/settings" element={<Authenticator />}>
        <Route path="/settings" element={<Settings />} />
      </Route>
      <Route path="/holdings" element={<Authenticator />}>
        <Route path="/holdings" element={<Holdings />} />
      </Route>
      <Route path="/watchlists" element={<Authenticator />}>
        <Route path="/watchlists" element={<WatchList className="mobile-watchlist" />} />
      </Route>
      {!isB2B && (
        <Route path="/plans" element={<Authenticator />}>
          <Route path="/plans" element={<Plan />} />
        </Route>
      )}
      <Route path="/publisher-api" element={<Authenticator />}>
        <Route path="/publisher-api" element={<PublisherApi broker={currentBrokerName} />} />
      </Route>
      <Route path="/terms_and_conditions" element={<TermsAndConditions />} />
      <Route path="/privacy_policy" element={<PrivacyPolicy />} />
      <Route path="/notfound" element={<NotFound />} />
      <Route path="/Partners" element={<Partners />} />
    </Routes>
  );
};

export default Routers;

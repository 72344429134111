import _ from 'lodash';
import {
  convertToNumber,
  getCancellableOrderStatus,
  parseExchangeTokenTradingSymbol,
  isValidSymbolForInsta,
} from '../utils';
import { getExchangeToken } from 'habitual-analytics/api/services/getExchangeToken';
import { PRETTIER_FORMAT_WITH_SECONDS } from 'habitual-analytics/dateUtils/dateFormats';
import {
  statusConfigs,
  transactionTypes,
} from 'habitual-analytics/constants/habitual-configs';
import { getDefaultProductCode, getPlaceOrderTradingSymbol } from '../tradingSymbolParser';
import { getRoundedData } from 'habitual-analytics/common/formatter/number';
import { getFormattedMoney } from 'habitual-analytics/utils/money';
import dayjs from 'dayjs';
import MarketUtility from 'habitual-analytics/utils/marketUtility';
import { getFormattedTradingSymbolObject } from 'habitual-analytics/brokers/tradingSymbolParser/index';

const parseProductCode = (pCode) => {
  let productCode;
  switch (_.toLower(pCode)) {
    case 'mis':
      productCode = _.toUpper('intraday');
      break;
    case 'nrml':
      productCode = _.toUpper('nrml');
      break;
    case 'cnc':
      productCode = _.toUpper('delivery');
      break;
    default:
      productCode = '';
      break;
  }
  return productCode;
};

const parsePlaceOrderProductType = (pCode) => {
  switch (_.toLower(pCode)) {
    case 'mkt':
      return 'MKt';
    case 'sl':
      return 'SL';
    case 'l':
      return 'L';
    case 'sl-m':
      return 'SL-M';
    default:
      break;
  }
};

const defaultParseOrderType = (orderType) => {
  let type;
  switch (_.toLower(orderType)) {
    case 'limit':
    case 'l':
      type = 'L';
      break;
    case 'mkt':
      type = 'MARKET';
      break;
    case 'sl':
      type = 'SL';
      break;
    case 'slm':
      type = 'SL-M';
      break;
    default:
      type = '';
  }
  return type;
};

const parseProductType = (pType) => {
  let type;
  switch (_.trim(_.toLower(pType))) {
    case 'l':
      type = 'LIMIT';
      break;
    case 'mkt':
      type = 'MARKET';
      break;
    case 'sl-m':
      type = 'SL-M';
      break;
    case 'sl':
      type = 'SL';
      break;
    default:
      type = '';
  }
  return type;
};

const sanitizeAndParseOrderStatus = (orderDetail) => {
  let placedOrderStatus = statusConfigs.placed.value;
  const status = _.get(orderDetail, 'orderStatus', '');

  switch (status) {
    case 'COMPLETE':
      placedOrderStatus = statusConfigs.executed.value;
      break;
    case 'CANCELED':
      placedOrderStatus = statusConfigs.cancelled.value;
      break;
    case 'REJECTED':
      placedOrderStatus = statusConfigs.failed.value;
      break;
    case 'OPEN':
      placedOrderStatus = statusConfigs.pending.value;
      break;
    default:
      placedOrderStatus = '';
      break;
  }
  return placedOrderStatus;
};

const parsePlaceOrder = (orderDetail) => {
  const { tradingSymbolObj } = orderDetail;
  const tradingSymbol = getPlaceOrderTradingSymbol(tradingSymbolObj);

  return {
    source: 'WEB',
    exchange: tradingSymbolObj?.isEquity() ? 'NSE' : 'NFO',
    tradingSymbol,
    qty: _.get(orderDetail, 'qty', 0),
    price: _.get(orderDetail, 'price', 0),
    product: _.get(orderDetail, 'pCode', ''),
    transType: _.get(orderDetail, 'transactionType', '') === 'buy' ? 'B' : 'S',
    priceType: parsePlaceOrderProductType(_.get(orderDetail, 'prctyp', '')),
    orderType: _.get(orderDetail, 'complexty', ''),
    ret: _.get(orderDetail, 'ret', ''),
    triggerPrice: _.get(orderDetail, 'trigPrice', 0),
    stopLoss: _.get(orderDetail, '', 0),
    disclosedQty: 0,
  };
};

const parseOrderBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');
  const tradingSymbolObj = getFormattedTradingSymbolObject(_.get(orderDetail, 'tradingSymbol', ''));
  if (
    !isValidSymbolForInsta(exchange) ||
    !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
  ) {
    return null;
  }
  const status = sanitizeAndParseOrderStatus(orderDetail);
  const tradedTime = _.get(orderDetail, 'orderTime', '');
  const failedReason = _.get(orderDetail, 'rejectedReason', '');
  const productType = parseProductType(_.get(orderDetail, 'priceType', ''));
  const productCode = parseProductCode(_.get(orderDetail, 'product', ''));
  const isCancellableOrder = getCancellableOrderStatus(status);
  const quantity = _.get(orderDetail, 'qty', 0);
  const tradedQty = _.get(orderDetail, 'fillShares', 0);
  const avgPrice = _.get(orderDetail, 'avgTradePrice', 0);
  const price = _.get(orderDetail, 'price', 0);
  const targetPrice = _.get(orderDetail, 'triggerPrice', 0);
  const tradedPrice =
    targetPrice !== null
      ? `${price} / ${targetPrice} trg`
      : avgPrice === null
        ? price
        : avgPrice;

  return {
    tradingSymbolObj,
    time: dayjs(tradedTime, 'HH:mm:ss DD-MM-YYYY')?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'transType', '') === 'S'
        ? transactionTypes?.sell.value
        : transactionTypes?.buy?.value,
    status: isCancellableOrder ? statusConfigs.pending.value : status,
    isCancellableOrder,
    failedReason,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${Math.abs(tradedQty)} / ${Math.abs(quantity)}`,
      tradedPrice,
      orderNo: _.get(orderDetail, 'orderNo', ''),
      defaultProductCode: getDefaultProductCode(_.get(orderDetail, 'product', ''), tradingSymbolObj),
      defaultProductType: defaultParseOrderType(_.get(orderDetail, 'priceType', '')),
    },
  };
};

const parseTradeBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');
  const tradingSymbolObj = getFormattedTradingSymbolObject(_.get(orderDetail, 'tradingSymbol', ''));
  if (
    !isValidSymbolForInsta(exchange) ||
    !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
  ) {
    return null;
  }

  const tradedTime = _.get(orderDetail, 'orderTime', '');
  const productType = parseProductType(_.get(orderDetail, 'priceType', ''));
  const productCode = parseProductCode(_.get(orderDetail, 'product', ''));
  const tradedQuantity = _.get(orderDetail, 'fillqty', 0);
  const quantity = _.get(orderDetail, 'qty', 0);
  const status = statusConfigs.executed.label;

  return {
    tradingSymbolObj,
    time: dayjs(tradedTime, 'HH:mm:ss DD-MM-YYYY')?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'transType', '') === 'S'
        ? transactionTypes?.sell.value
        : transactionTypes?.buy?.value,
    status,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQuantity} / ${quantity}`,
      tradedPrice: convertToNumber(_.get(orderDetail, 'fillprc', 0)),
    },
  };
};

const parseSubPositionBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');
  const tradingSymbolObj = getFormattedTradingSymbolObject(_.get(orderDetail, 'tradingsymbol', ''));
  if (
    !isValidSymbolForInsta(exchange) ||
    !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
  ) {
    return null;
  }

  const ltp = convertToNumber(_.get(orderDetail, 'ltp'));
  const qty = Number(_.get(orderDetail, 'netQty'), '0');
  const buyAvgPrice = _.get(orderDetail, 'buyPrice', 0);
  const sellAvgPrice = _.get(orderDetail, 'sellPrice', 0);
  const buyAvg = _.round(convertToNumber(buyAvgPrice), 2);
  const sellAvg = _.round(convertToNumber(sellAvgPrice), 2);

  const realisedProfitLoss = convertToNumber(_.get(orderDetail, 'realizedPnl', 0));
  const unRealisedProfitLoss =
    qty === 0
      ? 0
      : qty > 0
        ? (ltp - buyAvgPrice) * qty
        : (ltp - sellAvgPrice) * qty;
  const profitLoss = realisedProfitLoss + unRealisedProfitLoss;

  const type =
    Number(qty) < 0
      ? transactionTypes?.sell?.value
      : transactionTypes?.buy?.value;


  return {
    ...orderDetail,
    tradingSymbolObj,
    qty,
    buyAvg,
    sellAvg,
    ltp,
    profitLoss,
    symbol: tradingSymbolObj.toString(),
    extraDetails: {
      product: _.get(orderDetail, 'product', ''),
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'ltp',
      },
      defaultProductCode: getDefaultProductCode(
        _.get(orderDetail, 'product', ''),
        tradingSymbolObj
      ),
      order: orderDetail,
      isOpenPosition: qty !== 0,
      type: qty !== 0 ? type : '',
    },
  };

};

const parsePositionBook = (orderDetails) => {
  const isArray = _.isArray(orderDetails);
  if (isArray) {
    return _.map(orderDetails, (orderDetail) => parseSubPositionBook(orderDetail));
  }
  return parseSubPositionBook(orderDetails);
};

const parseHoldingsBook = (orderDetail) => {
  const symbol = _.get(orderDetail, 'symbol', '')[0];
  const exchange = _.get(symbol, 'exchange', '');
  const tradingSymbolObj = getFormattedTradingSymbolObject(_.get(symbol, 'tradingSymbol', ''));
  if (
    !isValidSymbolForInsta(exchange) ||
    !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
  ) {
    return null;
  }

  const quantity = _.get(orderDetail, 'netQty', 0);
  const orderValue = convertToNumber(_.get(orderDetail, 'buyPrice', 0));
  const ltp = convertToNumber(_.get(symbol, 'ltp', 0));
  const profitLoss = getRoundedData((ltp - orderValue) * quantity);
  const netChg = getRoundedData((profitLoss / orderValue) * 100);

  return {
    tradingSymbolObj,
    ltp,
    Nsetsym: tradingSymbolObj?.toString(),
    profitLoss,
    extraDetails: {
      quantity: `${quantity} (T1:${_.get(orderDetail, 'sellableQty', 0)})`,
      buyAverage: orderValue,
      buyValue: orderValue,
      netChg: `${getFormattedMoney(netChg)}%`,
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'CurrentPrice',
      },
      order: orderDetail,
    },
  };
};

const parseOrderDetails = (orderDetails, type) => {
  let formattedData = [];
  if (_.isArray(orderDetails)) {
    return _.map(orderDetails, (orderDetail) => {
      switch (type) {
        case 'order':
          return parseOrderBook(orderDetail);
        case 'trade':
          return parseTradeBook(orderDetail);
        case 'position':
          return parsePositionBook(orderDetail);
        case 'holdings':
          return parseHoldingsBook(orderDetail);
        default:
          return [];
      }
    });
  }
  return formattedData;
};

const parseModifyOrder = async (orderDetail) => {
  const { tradingSymbolObj } = orderDetail;
  const formattedExchangeTokenTradingSymbol =
    parseExchangeTokenTradingSymbol(tradingSymbolObj);
  const symbolId = await getExchangeToken(formattedExchangeTokenTradingSymbol);
  const tradingSymbol = getPlaceOrderTradingSymbol(orderDetail?.tradingSymbolObj);
  const price = _.get(orderDetail, 'price', '');
  const triggerPrice = _.get(orderDetail, 'trigPrice', 0);

  return {
    exchange: _.get(orderDetail, 'exch', ''),
    tradingSymbol: tradingSymbol,
    qty: _.toString(_.get(orderDetail, 'qty', '')),
    orderNo: _.get(orderDetail, 'orderNo', ''),
    price: !price ? '' : _.toString(price),
    transType: _.get(orderDetail, 'transactionType', '') === 'buy' ? 'B' : 'S',
    priceType: parsePlaceOrderProductType(_.get(orderDetail, 'prctyp', '')),
    product: _.get(orderDetail, 'pCode', ''),
    triggerPrice: !triggerPrice ? '' : _.toString(triggerPrice),
    disclosedQty: _.get(orderDetail, 'disCloseQty', ''),
    mktProtection: _.get(orderDetail, 'mktProtection', ''),
    target: _.get(orderDetail, 'targetPrice', ''),
    trailingPrice: _.get(orderDetail, 'trailingPrice', ''),
    stopLoss: _.get(orderDetail, '', ''),
    ret: _.get(orderDetail, 'ret', ''),
    token: _.toString(symbolId),
  };
};

const parseMarginCalculator = (orderDetail) => {
  const { tradingSymbolObj } = orderDetail;
  const tradingSymbol = getPlaceOrderTradingSymbol(tradingSymbolObj);
  const productType = _.get(orderDetail, 'prctyp', '');
  const isSlORder = productType === 'SL';
  const isSLandLimitOrder = productType === 'SL-M' || productType === 'L' || productType === 'SL';
  const exchange = tradingSymbolObj?.getExchangeSegment();
  
  return {
    exchange: exchange,
    tradingSymbol: tradingSymbol,
    qty: _.get(orderDetail, 'qty', 0),
    price: isSLandLimitOrder ? _.get(orderDetail, 'price', 0) : 0,
    product: _.get(orderDetail, 'pCode', 'NRML'),
    transType: _.get(orderDetail, 'transactionType', '') === 'buy' ? 'B' : 'S',
    priceType: parsePlaceOrderProductType(productType),
    orderType: _.get(orderDetail, 'complexty', 'Regular'),
    triggerPrice: isSlORder ? _.get(orderDetail, 'trigPrice', 0) : 0,
    stopLoss: 0,
  };
};

const parseAvailableMarginCalculator = () => {
  // this is the constant data only used to fetch the available margin
  return {
    exchange: 'NFO',
    tradingSymbol: 'ADANIENT26SEP24F',
    qty: 300,
    price: 0,
    product: 'MIS',
    transType: 'B',
    priceType: 'MKt',
    orderType: 'Regular',
    triggerPrice: 0,
    stopLoss: 0
  };
};

export {
  parsePlaceOrder,
  parseOrderBook,
  parsePositionBook,
  parseTradeBook,
  parseHoldingsBook,
  parseOrderDetails,
  parseModifyOrder,
  parseMarginCalculator,
  parseAvailableMarginCalculator
};

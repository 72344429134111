import { getStrikeIndexAndStrikeLabel } from 'habitual-analytics/formProvider/BasketFormProvider/processor';
import { getValidatedInstrumentAndExpiryType } from 'habitual-analytics/utils/instrument';
import { updateGlobalConfigs } from 'redux_store/action/index';
import { getLocalStorage, setLocalStorage } from './localStorage';
import MarketUtility from 'habitual-analytics/utils/marketUtility';

const BUFFER_TIME_MS = 60 * 1000; // 1 minute

export const onNavigateToCombinedPremium = (
  selectedInstrument,
  dispatch,
  navigate
) => {
  const { expiryDate } =
    getValidatedInstrumentAndExpiryType(selectedInstrument);

  dispatch(
    updateGlobalConfigs({
      headerConfigs: {
        instrument: selectedInstrument,
        expiryDate,
      },
      commonConfigs: {
        isNavigateToCombinedPremium: true,
      },
    })
  );

  navigate('/basket');
};

export const onNavigateOptionChain = (
  selectedInstrument,
  dispatch,
  navigate
) => {
  const { expiryDate } =
    getValidatedInstrumentAndExpiryType(selectedInstrument);

  dispatch(
    updateGlobalConfigs({
      headerConfigs: {
        instrument: selectedInstrument,
        expiryDate,
        strikePrices: [],
      },
      commonConfigs: {
        isNavigateToOptionChain: true,
      },
    })
  );

  navigate('/instruments');
};

export const onNavigateToPayoutChart = (orderConfigs, atm, dispatch) => {
  const basketLegs = _.map(
    orderConfigs,
    ({ tradingSymbolObj, transactionType, pCode, prctyp }, index) => {
      const lotSize = MarketUtility.getSymbolLotSize({
        symbol: tradingSymbolObj.symbol,
        date: tradingSymbolObj.expiryDate,
      });
      const strikeInterval = MarketUtility.getSymbolStrikeInterval({
        symbol: tradingSymbolObj.symbol,
        date: tradingSymbolObj.expiryDate,
      });

      return {
        optionType: tradingSymbolObj?.optionType,
        transactionType,
        strikePrice: tradingSymbolObj?.strikePrice,
        lot: tradingSymbolObj?.isEquity()
          ? 1
          : _.floor(Math.abs(orderConfigs[index]?.qty / lotSize)),
        legExpiry: tradingSymbolObj?.expiryDate,
        strikeIndex: getStrikeIndexAndStrikeLabel(
          atm,
          tradingSymbolObj?.strikePrice,
          strikeInterval
        ).strikeIndex,
        tradingSymbolObj,
        productType: prctyp,
        productCode: pCode,
        price: orderConfigs?.[index]?.price,
        isActivePosition: orderConfigs?.[index]?.isActivePosition,
      };
    }
  );
  
  dispatch(
    updateGlobalConfigs({
      basketConfigs: {
        legs: basketLegs,
      },
      commonConfigs: {
        isNavigateToPayoutChart: true,
      },
    })
  );
};

export const isAllSameOptionSymbol = (orderConfigs) => {
  return _.every(
    orderConfigs,
    ({ tradingSymbolObj }) =>
      tradingSymbolObj.symbol === orderConfigs[0].tradingSymbolObj.symbol &&
      tradingSymbolObj.getInstrumentType() === 'OPT'
  );
};

export const setDocumentProperty = (property, value) => {
  document.documentElement.style.setProperty(property, value);
};

export const getDocumentProperty = (property) => {
  return getComputedStyle(document.documentElement).getPropertyValue(property);
};

export const OriginUtility = (() => {
  const getEncodedOrigin = () =>
    encodeURIComponent(`${window.location.pathname}${window.location.hash}`) ||
    '';

  return {
    setOrigin: () => {
      const origin = getEncodedOrigin();
      const expiryTimestamp = Date.now() + BUFFER_TIME_MS;
      setLocalStorage('origin', JSON.stringify({ origin, expiryTimestamp }));
    },
    getOrigin: () => {
      const storedData = getLocalStorage('origin');
      if (!storedData) return '';

      const { origin, expiryTimestamp } = JSON.parse(storedData);
      if (Date.now() < expiryTimestamp) {
        return origin;
      }
      return '';
    },
  };
})();

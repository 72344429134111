import {
  getDynamicAppConfigs,
  initDynamicAppConfigs,
} from 'habitual-analytics/constants/dynamicAppConfigs';
import _ from 'lodash';
import Instrument from 'habitual-analytics/models/instrument/index';
import {
  getInstrumentDetailsByExchangeTokens
} from 'habitual-analytics/api/services/getInstrumentDetailsByExchangeTokens';

export const parseTradingSymbolObj = (orderDetail) => {
  const instrumentDetailsByExchangeTokens = _.get(
    getDynamicAppConfigs(),
    'instrumentDetailsByExchangeTokens',
    {}
  );

  const instrumentDetails = _.find(
    instrumentDetailsByExchangeTokens,
    (value, key) => key === orderDetail?.token || key === orderDetail?.nse_token
  );

  if (instrumentDetails.type === 'EQ') {
    return new Instrument({
      symbol: instrumentDetails.tradingsymbol,
    });
  } else {
    return new Instrument({
      symbol: instrumentDetails.symbol,
      expiryDate: instrumentDetails.expiryDate,
      strikePrice: instrumentDetails.strikePrice,
      optionType: instrumentDetails.type,
    });
  }
};

export const parseInstrumentDetailsByExchangeToken = async (orderDetails) => {  
  const exchangeTokens = _.map(orderDetails, (arr) =>
    _.get(arr, 'token', '') || _.get(arr, 'nse_token', '')
  );
  const tradingSymbolsByExchangeTokens = _.get(
    getDynamicAppConfigs(),
    'instrumentDetailsByExchangeTokens',
    {}
  );
  const missingTokens = _.filter(exchangeTokens, (token) =>
    _.isEmpty(tradingSymbolsByExchangeTokens[token])
  );

  if (missingTokens.length > 0) {
    const fetchedDetails = await getInstrumentDetailsByExchangeTokens(
      missingTokens
    );
    const formattedFetchDetails = _.keyBy(fetchedDetails, 'exchange_token');
    const mergedDetails = _.merge({}, tradingSymbolsByExchangeTokens, formattedFetchDetails);
    initDynamicAppConfigs({
      instrumentDetailsByExchangeTokens: mergedDetails,
    });
  }
};